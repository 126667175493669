// Segment Action Form Submit
$('.segmentFormTracking').submit(function() {
	var event = $(this).attr('data-segment-event');
	var name = $(this).attr('data-segment-name') ? $(this).attr('data-segment-name') : $(location).attr("href");
	var location = $(this).attr('data-segment-location');
	var category = $(this).attr('data-segment-category');

	if(!$(this).hasClass('pfah-form')) {
		if($(this).valid()) {
			analytics.track(event, {
				name: name,
				location: location,
				category: category,
				label: 'name: '+name+' | location: '+location
			});
		}
	}
});

$('.segmentModelSelected').click(function(e) {
    var selling_model = $(this).attr('data-model');

    analytics.track('Selling Model Selected', {
		selling_model: selling_model,
		area: 'Website',
		category: 'Sell Onboarding',
		label: selling_model+' | Website'
	});
});